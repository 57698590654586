<template>
    <!-- <label>{{$props.context.label}}</label> -->

    <Editor
        api-key="ijdjnnnbd5hibj5au4i6iann2ru1ipon26vasmwz4azikuk5"
        :value="localValue"
        v-model="localValue"
        
        model-events="blur"
        :init="getEditorConfig()"
        />

    
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { ref } from 'vue';

export default {
    components: {Editor},
    props: {
        context: {
            type:Object,
            required:true
        },

    },
    data() {
        return {
            valueCopy:'',
            

        }
    },
    methods: {
        getEditorConfig() {
            var _self = this;
            var toolbarOptions = ['blocks', 'forecolor backcolor', 'bold italic underline', 'link', 'bullist numlist'];
            //console.log('this', this.context.attrs.props)
            //console.log('this', _self)

            if (this.context.attrs.props.dropdownHelpers) {
                let dropdownOptions = [];
                for (var i = 0; i < _self.context.attrs.props.dropdownHelpers.length; i++) {
                    var option = _self.context.attrs.props.dropdownHelpers[i];
                    dropdownOptions.push(option);
                }
                if (dropdownOptions.length) toolbarOptions.push(dropdownOptions.join(' '));
            }

            // if (this.context.attrs.props.showVariables) {
            //     toolbarOptions.push('shortcodes blockshortcodes downloadshortcodes');
            // }
            if (this.context.attrs.props.showAllOptions) {
                toolbarOptions.push('table');
            }
            var toolbar = toolbarOptions.join(' | ');
            return {
                height: this.height,
                plugins: 'lists link image paste help wordcount table',
                menubar:false,
                toolbar: 'undo redo | blocks | forecolor backcolor | bold italic underline | alignleft aligncenter| link | bullist numlist outdent indent | shortcodes',
                toolbar: 'undo redo | blocks | forecolor backcolor | bold italic underline | alignleft aligncenter| link | bullist numlist | shortcodes',
                toolbar: 'blocks | forecolor backcolor | bold italic underline | link | bullist numlist | shortcodes blockshortcodes downloadshortcodes',
                toolbar: toolbarOptions.join(' | '),
                setup: function (editor) {
                    //console.log('setup', _self)

                    if (_self.context.attrs.props.dropdownHelpers) {
                        
                        for (var i = 0; i < _self.context.attrs.props.dropdownHelpers.length; i++) {
                            var option = _self.context.attrs.props.dropdownHelpers[i];

                            let splitButtonConfig = {
                                text: option,
                                options: _self.loadDropdownOptions(option),
                                //icon: 'link',
                                onAction: function (_) {
                                },
                                onItemAction: function (buttonApi, value) {
                                    editor.insertContent(value);
                                },
                                fetch: function (callback) {
                                    callback(splitButtonConfig.options);
                                }
                            }
                            editor.ui.registry.addSplitButton(option, splitButtonConfig);
                            
                        }
                    }

                }
            }
        },

        handleInput(e) {
            //this.$emit('msgChange', newInputValue);
            //console.log(e)
              this.$props.context.node.input(e.level.content)

        },
        async loadDropdownOptions(type) {
            var res  = await this.callApi('get', '/formconfig/wysiwygoptions/'+type);
            return res.data.result.map(item=>{return {
                type: 'choiceitem',
                text: item.name,
                value: item.value,
            }})
        },
        // async getMailTemplateVariables() {
        //     //console.log('getMailTemplateVariables')
        //     var res  = await this.callApi('get', '/mailtemplates/variables');
        //     return res.data.result.map(item=>{return {
        //         type: 'choiceitem',
        //         text: item.name,
        //         value: item.name,
        //     }})
        // },
        // async getMailTemplateDownloads() {
        //     //console.log('getMailTemplateVariables')
        //     var res  = await this.callApi('get', '/mailtemplates/downloads');
        //     return res.data.result.map(item=>{return {
        //         type: 'choiceitem',
        //         text: item.name,
        //         value: item.shortcode,
        //     }})
        // },
        // async getMailTemplateBlocks() {
        //     //console.log('getMailTemplateVariables')
        //     var res  = await this.callApi('get', '/mailtemplateblocks/blocks');
        //     return res.data.result.map(item=>{return {
        //         type: 'choiceitem',
        //         text: item.name,
        //         value: item.value,
        //     }})
        // }
    },
    computed: {
        height() {
                
                if (!this.$props.context.attrs.height) return 400;
                return this.$props.context.attrs.height;
            
        },
        localValue: {
            get () {
                // if (this.localValue) {

                //     return this.localValue
                // }
                return this.$props.context._value;
            },
            set (value) {
                //console.log("set",value)
                //this.localValue = value;
                this.$props.context.node.input(value);
            },

        }

        
    },
    watch: {
        // context(newVal) {
        //     this.valueCopy = newVal;
        // }
    }
}


</script>