<template>
  <!-- <input type="text" name="" v-model="email" id="login_email" placeholder="email" @keyup.enter.native="login"/>
  <input type="password" name="" v-model="password" id="login_password" placeholder="password" @keyup.enter.native="login"/>
  <button @click="login">Login!</button> -->
  Moment geduld aub...
</template>

<script>
export default {
    emits:['showActionBar', 'closeActionBar', 'showAlert'],
    data() {
        return {
            email:'',
            password:''
        }
    },
    methods: {
        async login() {
            const res = await this.callApi('post', '/login/login', {
                email:this.email,
                password:this.password
            });
            console.log(res)
            if (res.data.login) {
                this.$router.push('/customers');
            }
            else {
                //alert("Wrong email / password")
                this.showErrorMessage('Fout', "Login onjuist")
            }
        }
    }
}
</script>


<style>

</style>