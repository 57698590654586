<template>
    <!-- <label>{{$props.context.label}}</label> -->
    <div class="formTree">
        <!-- <Tree
            :nodes="tree.nodes"
            :config="tree.config"
            @nodeFocus="treeClick"
            ></Tree> -->

            <multiselect
                v-model="selectedValue"
                :options="options"
                label="name"
                track-by="id"
                placeholder=""
                :show-no-options="false"
                @search-change="search"
                :multiple="false">
                </multiselect>

    </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import "vue-multiselect/dist/vue-multiselect.css";
import { ref } from 'vue';

export default {
  components: {Multiselect},
  props: {
      context: {
          type:Object,
          required:true
      },
    //   nodes: {
    //       type:Object,
    //       required:true
    //   }
  },
  data() {
      return {
        selectedValue:false,
        // options:[
        //     // {id:1, name:'pietje'},
        //     // {id:2, name:'klaasje'},
        //     // {id:3, name:'jantje'},
        //     // {id:4, name:'sjakie'},
        //     // {id:5, name:'harrie'},
            
        // ],
      }
  },
  methods: {
      
      handleInput(e) {
          console.log('handleInput', e)
            //this.$props.context.node.input(e.level.content)

      },
      async search(e) {
          console.log('search', e)
          return;
          const res = await this.callApi('post', '/search', {search:e});
          console.log(res.data.result)
          this.options = res.data.result;
      },
      
  },
  computed: {
    options() {
        console.log('computed options', this.$props.context.attrs.options)
        let a = [];
        this.$props.context.attrs.options.forEach(option=>{
            a.push({id:option.value, name:option.label});
        })
        //let test = a.find(o=>o.id==this.$props.context._value);

        return a;
    },
    value() {
        return this.selectedValue;
        // var a = [];
        // this.selectedValue.forEach(id=>{
        //     a.push({id:id, name: this.options.find(o=>o.id==id).name})
        // })
        // return a;
    }
  },
  watch: {
    'context._value': function(newVal) {

        console.log('watch _value', newVal)
        let newOption = false;
        this.$props.context.attrs.options.forEach(option=>{
            if (option.value == newVal) newOption = {id:option.value, name:option.label};
            
        })
        if (newOption) this.selectedValue = newOption;
        //this.selectedValue = this.options.find(o=>o.id==newVal);
    },
    value(newVal) {
        console.log('watch value', newVal, this.selectedValue)
        //this.$props.context.node.input(newVal);
    },
    selectedValue(newVal) {
        console.log('watch', newVal, this.selectedValue)
          this.valueCopy = newVal;
          var a = [];
          if (this.selectedValue && this.selectedValue.id) {
              this.$props.context.node.input(this.selectedValue.id);
          }
          else {
              this.$props.context.node.input(false);
          }
          //this.$props.context.node.input(this.selectedValue.id);
      }
      // context(newVal) {
      //     this.valueCopy = newVal;
      // }
  },
  created() {
        console.log('autocomplete created', this.$props.context._value)
        
        if (!this.$props.context._value || this.$props.context._value == undefined)    return;
        
            this.selectedValue = this.options.find(o=>o.id==this.$props.context._value);

  },
  beforeMount() {
        console.log('autocomplete beforeMount', this.$props)
        // this.$props.context.attrs.options.forEach(option=>{
        //     this.options.push({id:option.value, name:option.label});
        // })

  },
  beforeUnmount() {
        //console.log('autocomplete beforeUnmount', this.$props.context._value)
        // this.autocomplete.nodes = {};
        // this.autocomplete.config.roots = [];
        // this.autocomplete.config.leaves = [];
        // this.$props.context.attrs.nodes =[]
  },
  mounted() {
        //console.log('autocomplete mounted', this.$props.context._value)
        //debugger;
        
  }
}
// const props = defineProps({
//     context: Object,
// })
// console.log(props)
// var testval = ref(props.context.value)
// function handleInput(e) {
//     console.log(props)
//     console.log(e)
//     testval = e.level.content
//     //props.context.node.input(e.target.value)
//     //props.context.node.input(e.level.content)
//     //props.context.node.input('234')
// }

</script>


<style>
.formkit-outer[data-type="autocomplete"] .multiselect__input {
    border:none !important;
}
.formkit-outer[data-type="autocomplete"] .multiselect__tags  {
    background-color:var(--cell-bg-color);
}
.formkit-outer[data-type="autocomplete"][data-empty=true] .multiselect__tags {
    padding: 1.20rem 4.2rem 1.60rem 1.8rem;
}
.formkit-outer[data-type="autocomplete"] .multiselect--active .multiselect__tags {
    padding: 0 !important;
    margin: 0 !important;
}
.formkit-outer[data-type="autocomplete"] .multiselect--active .multiselect__input {
    margin-bottom:0 !important
}
.formkit-outer[data-type="autocomplete"][data-complete=true] .multiselect__tags {
    padding: 1rem 4.2rem 0.4rem 1.8rem;
}
.formkit-outer[data-type="autocomplete"][data-complete=true] .multiselect__single {
    font-size: inherit;
    background: inherit;
}
</style>