<template>

  <div class="loader" :class="{active:loaderState}">
    <i class="fa-solid fa-spinner-third fa-spin"></i>
  </div>

  <dialog class="modal" id="dialog">
    <form @submit.prevent="()=>{}">
        <h2 v-if="dialog && dialog.title" class="modalTitle">{{dialog.title}}</h2>
        <p v-if="dialog && dialog.text" v-html="dialog.text"></p>

        <component v-if="dialog.component" :is="dialog.component" :data="dialog.data" />

        
        <FormKit v-if="dialog.fields && dialog.fields.length" type="form" v-model="dialogForm" :actions="false" @submit="dialog.buttons[dialog.buttons.length-1].action(this.dialogForm)">
            <FormKitSchema :schema="dialog.fields" />
        </FormKit>

        <div class="modalButtons" v-if="dialog">
            
          <template v-for="button in dialog.buttons">
              <button v-if="!button.action" class="modalButton modalButtonLine" @click="this.closeDialog">{{ button.label }}</button>
              <button v-else class="modalButton" :class="button.class" @click="()=>{button.action(this.dialogForm)}">{{ button.label }}</button>
          </template>
            <!-- <button class="modalButton modalButtonLine">Yup</button>
            <button class="modalButton modalButtonContrast">100%</button>
            <button class="modalButton">Jazeker</button> -->
        </div>
    </form>
  </dialog>
  
  <div class="alerts">
		

		<div v-for="alert in alerts" class="alert" :class="{debug:debugMode, alertSuccess:alert.type=='success', alertError:alert.type=='error', active:alert.active}">
			<div class="alertInfo">
				<p class="alertTitle">{{alert.title}}</p>
				<p class="alertMessage" v-html="$filters.nl2br(alert.message)"></p>
			</div>
			<a v-if="alert.title && alert.type=='error'" @click="hideAlert(alert.id)" class="alertClose cursor"><i class="fa-solid fa-xmark"></i></a>
		</div>
		
		
	</div>


  <template v-if="!isLoggedIn">
    <div v-if="!loginValues.forgotPassword" class="loginScreen">
      <figure class="loginLogo">
        <img :src="logoDefault" :alt="companyName+' logo'" />
      </figure>
      <div class="loginPanel" v-if="$route.path=='/login'">
        <h1>Inloggen</h1>
        <input type="email" v-model="loginValues.email" placeholder="E-mailadres" @keyup.enter.native="login"/>
        <input type="password" v-model="loginValues.password" placeholder="Wachtwoord" @keyup.enter.native="login"/>
        <input type="submit" @click="login" value="Inloggen" class="btn"/>
        
        <a class="cursor" @click="loginValues.forgotPassword=true">Wachtwoord vergeten?</a>
      </div>
      <div class="loginPanel setup2fa" v-if="$route.path=='/setup2fa'">
        <h1>2FA instellen</h1>
        <div style="float:left;">
          <span>Scan de QR code met de Google Authenticator app om 2-factor-authenticatie in te stellen.</span>
          <input type="email" v-model="loginValues.setup2facode" placeholder="Code" @keyup.enter.native="setup2fa"/>
          <input type="submit" @click="setup2fa" value="Instellen" class="btn"/>

        </div>
        <img :src="'data:image/png;base64, '+$store.getters.get_login.qrCode"/>

      </div>
      <div class="loginPanel setup2fa" v-if="$route.path=='/2fa'">
        <h1>2FA</h1>
        <div style="float:left;">
          <span>Voer de code in uit de Google Authenticator app.</span>
          <input type="email" v-model="loginValues.login2facode" placeholder="Code" @keyup.enter.native="verify2fa"/>
          <input type="submit" @click="verify2fa" value="Valideren" class="btn"/>

        </div>
        <img src="/img/logo-GoogleAuthenticator.svg"/>

      </div>

      
    </div> 
    
    <div v-if="loginValues.forgotPassword" class="loginScreen">
      <figure class="loginLogo">
        <img :src="logoDefault" :alt="companyName+' logo'" />
      </figure>
      <div class="loginPanel">
        <h1>Nieuw wachtwoord aanvragen</h1>
        
          <input type="email" v-model="loginValues.email" placeholder="E-mailadres"/>
          <input type="submit" @click="submitForgotPassword" value="Versturen" class="btn"/>
        
          <a class="cursor" @click="loginValues.forgotPassword=false">Terug naar inloggen</a>
      </div>
    </div>    
  
  </template>

  <component v-else-if="customComponent" :is="customComponent"></component>

  <template v-else-if="!initializing">
        
      <!---------------->
      <!-- MENU LINKS -->
      <!---------------->
      <section class="menuWrap" :class="{collapsed:!leftMenu.open }">
        <div class="menuTop">
          <figure class="logoWrap" :class="{collapsed:!leftMenu.open }">
            <!-- <img class="logoFull" src="/img/van-oss-logo-wit.png" alt="Van Oss Logo" />
            <img class="logoCollapsed" src="/img/van-oss-logo-wit-collapsed.png" alt="Van Oss Logo Collapsed" /> -->
            <img class="logoFull" :src="logoFull" :alt="companyName+' logo'" />
            <img class="logoCollapsed" :src="logoCollapsed" :alt="companyName+' logo collapsed'" />
          </figure>
          <nav class="mainMenu" :class="{collapsed:!leftMenu.open }">

            <router-link v-for="route in mainMenuItems" :key="route.path" :to="route.path" :class="{'active router-link-exact-active':isActiveMainMenu(route)}" >
              <!-- <i v-if="route.icon" :class="'fa-solid '+route.icon"></i><span>{{route.sectionTitle}}</span> -->
              <i v-if="route.icon" :class="'fa-solid '+route.icon">
                <span v-if="getSummedAlertCount(route)" class="summedAlertCount">{{ getSummedAlertCount(route) }}</span>
              </i><span>{{route.sectionTitle}}</span>
            </router-link>

          </nav>
        </div>

        <div class="menuBottom">
          <nav class="accountMenu" :class="{collapsed:!leftMenu.open }">
            <a v-if="isLoggedIn" class="cursor" @click="$router.push('/profile');">
              <i class="fa-solid fa-user"></i><span>{{getLogin.firstname}}</span>
            </a>
            <a v-if="isLoggedIn" @click="logout" class="cursor">
              <i class="fa-solid fa-right-from-bracket"></i><span>Uitloggen</span>
            </a>
            <div class="menuCollapseTrigger" @click="leftMenu.open=!leftMenu.open">
                <i class="fa-solid fa-circle-chevron-left"></i>
                <!-- <i v-if="!leftMenu.open" class="fa-solid fa-circle-chevron-right"></i> -->
                <span>Inklappen</span>
                
            </div>
          </nav>
        </div>
      </section>


      <!-------------------->
      <!-- CONTENT RECHTS -->
      <!-------------------->
      <section class="mainWrap" :class="mainWrapClass">

        <router-view
          @show-action-bar="showActionBar"
          @close-action-bar="closeSideBar"
          @close-side-bar="closeSideBar"
          @closeSideBar="closeSideBar"
          @closeActionBar="closeSideBar"
          @showAlert="showAlert"
          :key="$route.fullPath"
          />

      </section>

      <section :class="sidebarClass">
          <component
            v-if="sideBar.component"
            :is="sideBar.component"
            :_data="sideBar.data"
            
            @showActionBar="showActionBar"
            @closeSideBar="closeSideBar"
            @closeActionBar="closeSideBar"
            @close-side-bar="closeSideBar"
            @close-action-bar="closeSideBar"
            @show-alert="showAlert"
          ></component>
      </section>

  </template>

</template>

<script>
import { classExpression } from '@babel/types';


export default {
  emits:['showActionBar','closeSideBar','showAlert'],
  data() {
    return {
      initializing:true,
      baseColor: "blue",
      baseColor1: '#ff6600',
      debugMode:false,
      alerts: [],
      dialog: false,
      leftMenu:{
        open:1
      },
      loginValues: {
        forgotPassword:false,
        email:'',
        password:'',
        login2facode:'',
        setup2facode:''
      },
      sideBar: {
        size:'small',
        component:false,
        data:false
      },
      dialogForm:{},
      customComponent:false
      //sideBarComponent: false,
      //sideBarComponentData: false
    }
  },
  methods: {

    async verify2fa() {
        const res = await this.callApi('post', '/login/verify2fa', {
            login2facode:this.loginValues.login2facode
        });
        if (res.data.result) {
          this.$router.push(this.defaultUrl);
          this.clearAlerts();
          this.showAlert('success', '2FA', "2FA succesvol voltooid!")
          this.reloadStores()
        }
        else {
          this.showAlert('error', 'Fout', "2FA code onjuist")
        }
    },
    async setup2fa() {
        const res = await this.callApi('post', '/login/setup2fa', {
            setup2facode:this.loginValues.setup2facode
        });
        if (res.data.result) {
          this.$router.push(this.defaultUrl);
          this.clearAlerts();
          this.showAlert('success', '2FA', "2FA succesvol ingesteld!")
        }
        else {
          this.showAlert('error', 'Fout', "2FA code onjuist", true)
        }
    },

    async login() {
        const res = await this.callApi('post', '/login/login', {
            email:this.loginValues.email,
            password:this.loginValues.password
        });

        if (res.data.login) {
            if (res.data.login.loginstep) {
              this.$router.push('/'+res.data.login.loginstep);
              this.clearAlerts();
              this.showAlert('success', 'Login', "Succesvol ingelogd, voltooi 2FA!")


            }
            else {
              this.$router.push(this.defaultUrl);
              this.clearAlerts();
              this.showAlert('success', 'Login', "Succesvol ingelogd!")
              this.reloadStores()

              this.$store.dispatch('set_lastlogin', Math.floor(Date.now() / 1000))
                
            }
        }
        else {
            //alert("Wrong email / password")
            this.showAlert('error', 'Fout', "Login onjuist", true)
        }
    },
    async submitForgotPassword() {
        const res = await this.callApi('post', '/login/forgotPassword', {
            email:this.loginValues.email
        });
        this.showAlert('success', 'Nieuw wachtwoord', "Nieuw wachtwoord verzonden");
        this.loginValues.forgotPassword=false;
    },
    clearAlerts() {
      this.alerts = [];
    },
    hideAlert(id) {
      //console.log('hide', id)
      this.alerts.forEach(alert=>{
        if (alert.id==id) {
          alert.active=false;
          setTimeout(() => {
            this.removeAlert(id)
          }, 2000);
        }
      })
    },
    removeAlert(id) {
      this.alerts = this.alerts.filter(function( alert ) {
          return alert.id !== id;
      }); 
    },
    showAlert(type, title, message, autoclose) {
      console.log('showAlert', type, title, message, autoclose)
      var id = Math.floor(Math.random() * 1000000000);
      this.alerts.push({
          'id':id,
          'type':type,
          'title':title,
          'active':true,
          'message':message
      })

      if (type=='success' || autoclose) {
        setTimeout(() => {
          this.hideAlert(id)
        }, 2000);
      }
    },
    async checkLogin() {
      const res = await this.callApi('get', '/login/check');
      //console.log(res)
      this.initializing = false;
      if (res.data.debugMode) this.debugMode = 1;
      if (!res.data.login) {
        console.log("logged out", this.$router)
        this.$router.push('/login');
        this.hideLoader();
        //document.location.href = '/';
      }
      else if (res.data.login.loginstep) {
        this.$router.push('/'+res.data.login.loginstep);
        this.hideLoader();
        
      }
      else if (this.$route.path=='/login') {
        this.$router.push(this.defaultUrl);
        this.reloadStores()
      }
      else {
        this.reloadStores()
      }
    },
    async logout() {
      var res = await this.callApi('post', '/login/logout');
      setTimeout(() => {
        this.$router.push('/login');
      }, 1000);
      //this.$router.push('/');
    },
    async reloadStores() {
        //console.log('reloadStores')
        const res = await this.callApi('get', '/base/getInitStores');
        if (res.data.result && res.data.result.stores) {
          for (const key in res.data.result.stores) {
            //console.log(key, res.data.result.stores[key])
            this.$store.dispatch('set_'+key, res.data.result.stores[key])
          }
        }
        //this.$store.dispatch('set_login', res.data.login)
    },

    closeSideBar() {
      //console.log('closeSideBar')
      this.sideBar.component=false;
      this.sideBar.data = false;
    },
    showActionBar(component, data, size) {
      //console.log("showActionBar", data)
      if (this.sideBar.component) {
        this.closeSideBar()
        var that = this;
        setTimeout(function(){
          that.showActionBar(component, data, size)
        }, 200)
        return;
      }
      this.sideBar.size = size;
      this.sideBar.component = component;
      this.sideBar.data = data;
    },
    isActiveMainMenu(route) {
      if (this.$route.path=='/') return false;

      var mainSection = this.$route.path.split('/')[1];
      if (route.path=='/'+mainSection) {
        //console.log(route.path, mainSection);
        return true;
      }
      return false;
    }
  },
  watch: {
    '$route' (to, from) {
        //console.log(to,from)
        if (to.path=='/') {
          this.$router.push(this.defaultUrl)
          return
        }
        //to.loaded();
        this.closeSideBar()
        //this.updateAlertCounts()
    }
  },
  computed: {

    cssVars() {
      return {
        'baseColor1': '#310E4E'
      }
    },

    logoDefault() { return process.env.VUE_APP_LOGO_DEFAULT; },
    logoFull() { return process.env.VUE_APP_LOGO_FULL; },
    logoCollapsed() { return process.env.VUE_APP_LOGO_COLLAPSED; },
    companyName() { return process.env.VUE_APP_COMPANY_NAME; },

    loaderState() {
      //console.log('loaderState', this.$store.getters.getLoaderState)
      return this.$store.getters.get_loaderstate?true:false
    },
    mainWrapClass() {
      var c = [];
      var routes = this.$router.options.routes.filter(route=>{return route.name==this.$route.name});
      if (!this.leftMenu.open) c.push('collapsed');
      if (routes.length==1 && routes[0].className) c.push(routes[0].className);
      return c.join(' ');
    },
    sidebarClass() {
      if (this.sideBar.component) {
        var classes = ['sideBarWrap'];
        if (this.sideBar.size!='large') {
          classes.push('actionsSideBar');
        }
        classes.push('active');
        return classes.join(' ');
      }
      return 'sideBarWrap';
    },
    sidebarSize() {
      if (this.sideBar.component) {
          if (this.sideBar.size=='large') {
            return '600px';
          }
          else {
            return '400px';

          }
      }
      return '0px';
    },
    isLoggedIn() {
      let login = this.$store.getters.get_login
      if (!login) return false;
      if (login.loginstep) return false;
      return this.$store.getters.get_login?true:false
    },
    getMainSectionTitle(section) {
      //console.log(this.mainMenuItems())
    },
    mainMenuItems() {

      var login = this.$store.getters.get_login;
      var allowedPages = [];
      if (login && login.pagePermissions) allowedPages = login.pagePermissions;
      
      return this.$router.options.routes.filter(route=>{
        if (!route.sectionTitle) return false;
        if (!route.icon) return false;
        if (login && login.Role && login.Role.superuser) return true;
        if (!allowedPages.includes(route.name)) return false;
        return (route.sectionTitle && route.path.match(/\//g) || []).length==1
      });
      
    },


    
    
  },
  async beforeMount() {
    //console.log('App beforeMount')
    this.checkLogin()
  },  
  
  beforeDestroy() {
      window.addEventListener('keydown', this.handleKeydown);
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
    //console.log('App mounted')
    //this.checkLogin()

    this.emitter.on('closeSideBar', e => {
      //console.log("closeSideBar by mitt", e)
      this.sideBar.component=false;
      this.sideBar.data = false;
    })
    this.emitter.on('showAlert', e => {
      console.log("showAlert by mitt", e)

      this.showAlert(e.type, e.title, e.message, e.autoclose)
    })
    this.emitter.on('showDialog', e => {
      this.dialog = {};
      this.dialog.title = e.title;
      this.dialog.text = e.text;
      this.dialog.fields = e.fields;
      this.dialog.component = e.component;
      this.dialog.data = e.data;

      this.dialog.buttons = e.buttons;
      this.dialogForm = {};
      if (e.formData) {
        for (const key in e.formData) {
          this.dialogForm[key] = e.formData[key];
        }
      }
      document.getElementById('dialog').showModal();
    })
    this.emitter.on('hideDialog', e => {
      document.getElementById('dialog').close();
      //dialog.close();
      this.dialog = false;
    })
    this.emitter.on('showCustomComponent', e => {
      this.customComponent = e.component;
      console.log(this.customComponent)
    })

    //this.reloadStores()
    setTimeout(()=>{
      this.updateAlertCounts()
    }, 2000)
    setInterval(()=>{
      this.updateAlertCounts()
    //}, 30000);
    }, 30000);
  }
}
</script>

<style>
.alerts .alert.debug {
  width:120rem;
}

.sideBarWrap {
  transition: right 0.3s;
}


.marked {
  background-color: yellow;
      background-color: yellow;
    border: 1px solid #310e4e;
    border-radius: 1rem;
    padding: 0.2rem 0.7rem 0.1rem 0.7rem;
}

.loginPanel.setup2fa img {
  width:200px;
  float:right;
}
.loginPanel.setup2fa div {
  float:left;
  width:60%;
  text-align: left;
}
.loginPanel.setup2fa div span {
  text-align: left;
  float:left;
  clear: both;
  margin-bottom: 2em;
  font-size: 1.4rem;
}



/* CSS to fix icons in formkit */
.formkit-outer[data-prefix-icon] .formkit-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width:100%;
}

.formkit-outer[data-prefix-icon] .formkit-input[type=text],
.formkit-outer[data-prefix-icon] .formkit-input[type=email],
.formkit-outer[data-prefix-icon] .formkit-input[type=tel],
.formkit-outer[data-prefix-icon] .formkit-input[type=date],
.formkit-outer[data-prefix-icon] .formkit-input[type=number] {
  width:calc(100% - 2em) !important; 
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left:none !important;
}

.formkit-outer[data-prefix-icon] .formkit-input[type=color] {
  border:1px solid var(--input-field-line-color) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left:none !important;
}

.formkit-outer:focus-within .formkit-icon.formkit-prefix-icon {
  border-color: var(--base-color-2);
}

.formkit-outer .formkit-icon.formkit-prefix-icon svg {
  height:20px;
}

.formkit-outer .formkit-icon.formkit-prefix-icon {
  text-align: center;
  color:var(--base-color-1);
  width:4em;
  
  padding:1.1rem 1rem;
  background: var(--base-color-3);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.07), transparent);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border:1px solid var(--input-field-line-color);
  height:43.77px;
}
.formkit-outer[data-type=date] .formkit-icon.formkit-prefix-icon {
  height:45.77px;
}

.formkit-form .formkit-outer[data-prefix-icon] .formkit-wrapper .formkit-inner .formkit-floating {
  left:3.8em;
}


/* @todo: fix for disabling floating labels for date and time fields */
.formkit-form .formkit-outer[data-type=date] .formkit-floating,
.formkit-form .formkit-outer[data-type=time] .formkit-floating {
    font-size: 1.2rem !important;
    color: var(--base-color-2) !important;
    transform: translateY(50%) !important;
    bottom: 100% !important;
    display: inline-block !important;
    padding: 0.5rem !important;
    font-weight: var(--font-weight-bold) !important;
    background: var(--white) !important;
    background: linear-gradient(0deg, var(--cell-bg-color) 50%, var(--white) 50%) !important;
}

.hide {display: none;}



</style>

