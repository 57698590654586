<template>
    <!-- <label>{{$props.context.label}}</label> -->
    <div class="formTree">
        <Tree
            :nodes="tree.nodes"
            :config="tree.config"
            @nodeFocus="treeClick"
            ></Tree>

            

    </div>
</template>

<script>
import Tree from "vue3-treeview";
import "vue3-treeview/dist/style.css";
import { ref } from 'vue';
import NotesAppVue from "@/components/custom/Notes/NotesApp.vue";

export default {
  components: {Tree},
  props: {
      context: {
          type:Object,
          required:true
      },
    //   nodes: {
    //       type:Object,
    //       required:true
    //   }
  },
  data() {
      return {
          //valueCopy:'',
          tree:{
            nodes: {},
            config: {
                roots:[],
                leaves:[],
                // openedIcon: {
                //     type: "class",
                //     class: "fas fa-folder-open",
                // },
                // closedIcon: {
                //     type: "class",
                //     class: "fas fa-folder",
                // },
            }
          },
      }
  },
  methods: {
      treeClick(item) {
        //return;
          console.log('treeClick', item)
          if (item.type=='file') {
                this.$props.context.node.input(item.fileId);
                Object.entries(this.tree.nodes).forEach(node=>{
                    if (node[1].type=='file') {
                        console.log(node[1])
                        if (node[1].fileId==item.fileId) {
                            console.log("found", node)
                            node[1].state = {
                                checked:true
                            }
                        }
                        else {
                            node[1].state = {
                                checked:false
                            }

                        }
                    }
                })
          }
      },
      handleInput(e) {
          console.log('handleInput', e)
            //this.$props.context.node.input(e.level.content)

      },
      openFolder(id) {
        console.log('openFolder')
           var nodeId = 'node_'+id;
           if (this.tree.nodes[nodeId]) {
                this.tree.nodes[nodeId].state = {
                    opened: true
                }
                if (this.tree.nodes[nodeId].parent_id) {
                    this.openFolder(this.tree.nodes[nodeId].parent_id)
                }
           }
      }
  },
  computed: {
    //   height() {
              
    //           if (!this.$props.context.attrs.height) return 400;
    //           return this.$props.context.attrs.height;
          
    //   },
    //   localValue: {
    //       get () {
    //         console.log('get')
    //         if (this.localValue) {
                
    //             return this.localValue
    //         }
    //         return this.$props.context._value;
    //     },
    //     set (value) {
              
    //           console.log("set",value)
    //           //this.localValue = value;
    //           this.$props.context.node.input(value);
    //       },

    //   }
      // propModel: {
      //     get () { return this.$props.context.value },
      //     set (value) { this.$props.context.value.set(value); console.log(value);this.$emit('update:prop', value) },
      // },        
      // val() {
      //     console.log(this.$props)
      //     return this.$props.context.value;
      // }
  },
  watch: {
      // context(newVal) {
      //     this.valueCopy = newVal;
      // }
  },
  created() {
        console.log('tree created', this.$props.context._value)

  },
  beforeMount() {
        console.log('tree beforeMount', this.$props.context._value)

  },
  beforeUnmount() {
        console.log('tree beforeUnmount', this.$props.context._value)
        this.tree.nodes = {};
        this.tree.config.roots = [];
        this.tree.config.leaves = [];
        this.$props.context.attrs.nodes =[]
  },
  mounted() {
        console.log('tree mounted', this.$props.context._value)
        //debugger;
        var newNodes = {};
        var newRoots = [];
        var newLeaves = [];
        //this.tree.config.roots = [];
        //this.tree.config.leaves = [];

        var openCategoryId = false;
        
        var nodeMapping = {};


        this.$props.context.attrs.nodes.forEach(fc => {
            const nodeId = 'node_'+fc.id;
            if (!fc.parent_id) newRoots.push(nodeId)


            const node = {
                id: fc.id,
                fc_id: fc.id,
                text: fc.name,
                files: fc.Files
            };
            if (fc.FileCategories.length) {
                node.children = [];
                fc.FileCategories.forEach(child => {
                    const childId = 'node_'+child.id;
                    node.children.push(childId);
                });
            }
            newNodes[nodeId] = node;
        });

        // this.$props.context.attrs.nodes.forEach(fc=>{
        //     //this.tree.config.roots.push('node_'+node.id)
        //     var nodeId = 'node_'+fc.id+"_"+new Date().valueOf();

        //     nodeMapping[fc.id] = nodeId;
        //     //console.log(fc)
        //     if (!fc.parent_id) newRoots.push(nodeId)
            


        //     const node = {
        //         type:'folder',
        //         id: fc.id,
        //         fc_id: fc.id,
        //         text: fc.name,
        //         parent_id: fc.parent_id,
        //         //files: fc.Files,
        //         children: []
        //     };

        //     // fc.Files.forEach(file=>{
        //     //     var fileNodeId = 'file_'+file.id+"_"+new Date().valueOf();
        //     //     const fileNode = {
        //     //         type:'file',
        //     //         id: file.id,
        //     //         fileId: file.id,
        //     //         text: file.name,
        //     //     };
        //     //     if (file.id==this.$props.context._value) {
        //     //         openCategoryId = node.id;
        //     //         node.state = {
        //     //             opened: true,
        //     //         }
        //     //         fileNode.state = {
        //     //             checked: true
        //     //         }
        //     //     }
        //     //     newLeaves.push(fileNodeId)
        //     //     newNodes[fileNodeId] = fileNode;
        //     //     node.children.push(fileNodeId);
        //     // })

        //     this.$props.context.attrs.nodes.filter(fc2=>{return fc2.parent_id==fc.id;}).forEach(fc2=>{
                
        //             //node.children.push('node_'+fc2.id)
        //             node.children.push(nodeMapping[fc2.id])
                
        //     })

        //     newNodes[nodeId] = node;

        // })
        //const nodeId = 'node_1';
        console.log('openCategoryId', openCategoryId)
        //this.openFolder(openCategoryId)

        this.tree.nodes = newNodes;
        this.tree.config.roots = newRoots;
        this.tree.config.leaves = newLeaves;

        // const node = {
        //     id: 1,
        //     fc_id: 1,
        //     text: 'base',
        //     files: []
        // };

        // this.tree.nodes[nodeId] = node
  }
}
// const props = defineProps({
//     context: Object,
// })
// console.log(props)
// var testval = ref(props.context.value)
// function handleInput(e) {
//     console.log(props)
//     console.log(e)
//     testval = e.level.content
//     //props.context.node.input(e.target.value)
//     //props.context.node.input(e.level.content)
//     //props.context.node.input('234')
// }

</script>


<style>
.formTree .node-wrapper.checked .node-text {
    /* background:red; */
}
.formTree .node-wrapper.checked {
    background:#ccc;
}

.formTree .tree-level .tree-node .node-wrapper:hover {
    background:#ccc;

}

.formTree .tree-level .tree-node .node-wrapper .input-wrapper .node-text:before {
    content: none !important;
}

.formTree .tree-level .tree-node .node-wrapper .input-wrapper .node-text {
    padding-left: 0;
}

</style>