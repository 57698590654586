<template>
    <div v-if="isModal" class="tiptapModalBackground"></div>
    <div v-if="editor" class="swifTiptapToolbar" :class="{modalTiptap:isModal}">
        <input v-if="hasControl('textColor')"
        type="color"
        @input="editor.chain().focus().setColor($event.target.value).run()"
        :value="editor.getAttributes('textStyle').color"
        >
        <button v-if="hasControl('bold')" type="button" @click="this.editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
            <i class="fa-solid fa-bold"></i>
        </button>
        <button v-if="hasControl('italic')" type="button" @click="this.editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
            <i class="fa-solid fa-italic"></i>
        </button>
        <button v-if="hasControl('underline')" type="button" @click="this.editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
            <i class="fa-solid fa-underline"></i>
        </button>
        
        <button v-if="hasControl('alignLeft')" type="button" @click="this.editor.chain().focus().setTextAlign('left').run()" :class="{'is-active': editor.isActive({textAlign:'left'})}">
            <i class="fa-solid fa-align-left"></i>
        </button>
        <button v-if="hasControl('alignCenter')" type="button" @click="this.editor.chain().focus().setTextAlign('center').run()" :class="{'is-active': editor.isActive({textAlign:'center'})}">
            <i class="fa-solid fa-align-center"></i>
        </button>
        <button v-if="hasControl('alignRight')" type="button" @click="this.editor.chain().focus().setTextAlign('right').run()" :class="{'is-active': editor.isActive({textAlign:'right'})}">
            <i class="fa-solid fa-align-right"></i>
        </button>

        <button v-if="hasControl('list')" type="button" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
            <i class="fa-solid fa-list"></i>
        </button>
        <button v-if="hasControl('numberedList')" type="button" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
            <i class="fa-solid fa-list-ol"></i>
        </button>

        <button v-if="hasControl('highlight')" type="button" @click="editor.chain().focus().toggleHighlight().run()" :class="{ 'is-active': editor.isActive('highlight') }">
            <i class="fa-solid fa-brush"></i>
        </button>

        <button v-if="hasControl('link')" type="button" @click="toggleLink" :class="{ 'is-active': editor.isActive('link') }">
            <i class="fa-solid fa-link"></i>
        </button>

        <button v-if="hasControl('image')" type="button" @click="toggleImage" :class="{ 'is-active': editor.isActive('image') }">
            <i class="fa-solid fa-image"></i>
        </button>

        <select v-if="_dropdowns" v-for="(dropdown,dropdownKey) in _dropdowns" class="customDropdown" @change="customDropdownAction">
            
            <option value="">{{dropdown}}</option>
            <option v-for="option in dropdownOptions[dropdown]" :value="option.value">{{option.text}}</option>
            
        </select>


        <button type="button" @click="toggleModal" :class="{ 'is-active': isModal }">
            <i class="fa-solid" :class="{'fa-expand':!isModal, 'fa-compress':isModal}"></i>
        </button>
    
    </div>
    <editor-content
        :editor="editor"
        v-model="localValue"
        class="swifTiptapEditor"
        :class="{modalTiptap:isModal}"
        :style="{height:height+'px'}"
    />

    


</template>


<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import Link from '@tiptap/extension-link'

import Paragraph from '@tiptap/extension-paragraph'

import TextAlign from '@tiptap/extension-text-align'

import Image from '@tiptap/extension-image'

import Highlight from '@tiptap/extension-highlight'



import { Color } from '@tiptap/extension-color'

import StarterKit from '@tiptap/starter-kit'


export default {
    components: {
        EditorContent
    },
    
    props: {
        context: {
            type:Object,
            required:true
        },

    },
    beforeUnmount() {
        this.editor.destroy()
    },
    data() {
        return {
            initialized: false,
            editor: null,
            ready: false,
            isModal: false,
            dropdownLoadingStarted: {},
            dropdownOptions: {},
            _options: []
        }
    },
    computed: {
        _dropdowns() {
            return this.context.attrs.props.dropdownHelpers;
        },
        
        options() {
            //console.log('editor options', this._options)
            return this._options;
            
        },

        height() {
                
            if (!this.$props.context.attrs.height) return 400;
            return this.$props.context.attrs.height-59;
        
        },

        localValue: {
            get () {
                // return;
                // if (this.localValue) {

                //     return this.localValue;
                //     return this.localValue.replace(/\t|\n/g, "");
                // }
                return this.$props.context._value;
            },
            set (value) {
                //return;
                //console.log("set",value)
                //this.localValue = value;
                value = value.replace(/\t|\n/g, "");
                this.$props.context.node.input(value);
            },

        },
    },
    watch: {
        localValue: {
            handler: function (newValue, oldValue) {
                console.log('watch', oldValue, newValue)
                if (newValue == oldValue) return;
                
                if (!oldValue && newValue) {

                    if (this.initialized) {
                        console.log('update (watch)')
                        this.editor.commands.setContent(newValue);
                    }
                    else {
                        console.log('init (watch)')
                        this.init();

                    }
                }
                //this.$props.context.node.input(value);
            },
            deep: true
        }
    },
    methods: {
        hasControl(control) {
            if (!this.context.attrs.props.controls) return true;
            return this.context.attrs.props.controls.includes(control);
        },
        customDropdownAction(e) {
            //console.log('customDropdownAction', e.target.value)
            this.editor.chain().focus().insertContent(e.target.value).run()

            // set the value back to the default
            e.target.value = '';
        },
        toggleModal() {

            
            this.isModal = !this.isModal;

            
        },

        toggleImage() {
            const imageUrl = this.editor.getAttributes('image').src
            if (imageUrl) {
                // image already set
            }
            else {
                // image not set yet, show upload form
                this.showFormDialog({
                    title: 'Afbeelding uploaden',
                    text: 'Selecteer een afbeelding',
                    fields: [
                        {
                            "name": "image",
                            "id": "image",
                            "$formkit": "file",
                            "label": "Afbeelding"
                        },
                    ],
                    
                    buttons:[
                        {label:'Annuleren', action:()=>{
                            //this.editor.chain().extendMarkRange('link').focus().unsetLink().run()
                            this.closeDialog();
                        }, class:'modalButtonLine'},
                        {label:'Uploaden', action:async (e)=>{
                            
                            console.log(e)
                            //let res  = await this.callApi('post', '/formconfig/uploadImage', {image:e.image[0]});
                            let imagePermission = 'private';
                            if (this.context.attrs.props.imagePermission) {
                                imagePermission = this.context.attrs.props.imagePermission;
                            }

                            const res = await this.uploadFileAndData('/formconfig/uploadImage', {_imagePermission:imagePermission}, e.image[0].file);

                            this.editor.chain().focus().setImage({ src: process.env.VUE_APP_API_BASE+res.data.result }).run();
                            this.closeDialog();
                        }}
                    ]
                })
            }
        },
        toggleLink() {

            const previousUrl = this.editor.getAttributes('link').href
            this.showFormDialog({
                title: 'Link instellen',
                text: 'Stel een link in',
                fields: [
                    {
                        "name": "url",
                        "id": "url",
                        "$formkit": "text",
                        "label": "URL"
                    },
                ],
                formData: {
                    url: previousUrl,
                },
                buttons:[
                    {label:'Link verwijderen', action:()=>{
                        this.editor.chain().extendMarkRange('link').focus().unsetLink().run()
                        this.closeDialog();
                    }, class:'modalButtonLine'},
                    {label:'Instellen', action:(e)=>{
                        this.editor.chain().extendMarkRange('link').focus().setLink({ href: e.url }).run()
                        this.closeDialog();
                    }}
                ]
            })
            return;

        },
        
        initDropdownOptions() {
            if (this.context.attrs.props.dropdownHelpers) {
                //console.log('dropdownhelpers', this.context.attrs.props.dropdownHelpers)
                // let dropdownOptions = [];
                for (var i = 0; i < this.context.attrs.props.dropdownHelpers.length; i++) {
                    if (!this.dropdownOptions[this.context.attrs.props.dropdownHelpers[i]]) {
                        //console.log('start adding dropdown', this.context.attrs.props.dropdownHelpers[i])
                        this.dropdownOptions[this.context.attrs.props.dropdownHelpers[i]] = [];
                        this.loadDropdownOptions(this.context.attrs.props.dropdownHelpers[i]);

                    }

                }
            }
        },
        async loadDropdownOptions(type) {
            if (this.dropdownLoadingStarted[type]) return;
            this.dropdownLoadingStarted[type] = true;
            var res  = await this.callApi('get', '/formconfig/wysiwygoptions/'+type);

            this.dropdownOptions[type] = res.data.result.map(item=>{return {
                type: 'choiceitem',
                text: item.name,
                value: item.value,
            }})
            //this.dropdownOptions[type] = res.data.result.map(item=>{return item.name})

            //console.log('dropdownOptions', type, this.dropdownOptions)
            
        },

        init() {
            if (this.initialized) return;
            this.editor = new Editor({
        
                content: this.localValue,
                onBlur: ({ editor }) => {
                    //console.log('update', editor.getHTML())
                    //this.localValue = editor.getHTML();
                    this.$props.context.node.input(editor.getHTML());
                },
                extensions: [
                    Underline,
                    Paragraph,
                    TextStyle,
                    Color,
                    Highlight.configure({ multicolor: true }),
                    Link.configure({
                        openOnClick: false,
                    }),
                    TextAlign.configure({
                        types: ['heading'],
                        alignments: ['left', 'center', 'right'],
                        defaultAlignment: 'left',
                    }),
                    Image.configure({
                        inline: true,
                        allowBase64: true,
                    }),
                    StarterKit,
                ],
            })
            this.initialized = true;
        }
    },
    mounted() {
        //this.setOptions();
        this.initDropdownOptions();

        

        setTimeout(()=>{
            console.log('init (mounted, timeout)')
            this.init();
            //this.ready = true;
        }, 600)

        //console.log('mounted', this.$refs.quill.getQuill().getModule('toolbar'))
        
    },
  
}

</script>

<style>

.tiptapModalBackground {
    position: fixed; /* Stay in place */
    
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    
}

.modalTiptap {
    position:relative;
}
.formkit-outer:has(.modalTiptap) {
    position: fixed;
    left: 5%;
    width: 90%;
    max-width: 90%;
    top: 5vh;
    z-index: 10;
    box-shadow: 0 30px 30px 30px rgba(0, 0, 0, 0.25);
}
.formkit-outer:has(.modalTiptap) .swifTiptapEditor {
    height:80vh !important;
}

.swifTiptapToolbar {
    border: 1px solid var(--input-field-line-color);
    border-bottom:1px solid #ccc;
    background: var(--cell-bg-color);
    background: white;
    border-radius: 10px 10px 0 0;
    padding-top: 1em;
    padding-left: 1em;
    font-size:1.2em;
}
.swifTiptapToolbar button {
    background: var(--cell-bg-color);
    background: white;
    color: var(--cell-color);
    border: none;
    border-radius: 5px;
    padding: 0.25em;
    margin-bottom:0.5em;
    font-size:1.4em;
    width:30px;
    height:30px;
}


.swifTiptapToolbar .customDropdown {
    width:100px !important;
    padding: 0 0.5em 0.5em 0.5em !important;
    border: none    !important;
    background: white !important;
    margin-right:5px;
}

.swifTiptapToolbar button, .swifTiptapToolbar input {
    margin-right:0.5em;
}
.swifTiptapToolbar input {
    height:21px;
    vertical-align: baseline;
}
.swifTiptapToolbar button:hover, .swifTiptapToolbar button.is-active {
    background: var(--cell-bg-color);
    background: #c8cbcf;
    
    
}
.swifTiptapEditor {
    border: 1px solid var(--input-field-line-color);
    border-top:none;
    background: var(--cell-bg-color);
    background: white;
    border-radius: 0 0 10px 10px;
    height:300px;
    padding:1em;
    font-size: 1.8em;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    overflow-y: scroll;
}
.swifTiptapEditor .tiptap ul li, .swifTiptapEditor .tiptap ol li{
    margin-left:1.25em !important;
}

.swifTiptapEditor .tiptap p {
    margin-bottom: 1em;
}


.swifTiptapEditor img {
    width:auto !important;
}

.swifTiptapEditor mark {
    font-weight: normal;
    font-style: normal;
}
</style>